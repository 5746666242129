var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c("div", { staticClass: "layTop" }, [
            _c(
              "div",
              {
                staticClass: "Title",
                staticStyle: { "margin-bottom": "20px" }
              },
              [_vm._v(_vm._s(_vm.$t("Department")))]
            )
          ]),
          [
            _c(
              "el-row",
              { staticClass: "topFromSearch", attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: { height: "auto" },
                    attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 }
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c(
                          "span",
                          {
                            style:
                              _vm.departmentStatus == "" ||
                              _vm.departmentStatus == null
                                ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                : "top: -10px;left: 20px;transform: translate(0, 0);",
                            attrs: { id: "StatusSpan" }
                          },
                          [_vm._v(_vm._s(_vm.$t("STATE")) + ":")]
                        ),
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              clearable: "",
                              size: "small",
                              placeholder: ""
                            },
                            on: {
                              change: _vm.Statuschang,
                              focus: function($event) {
                                return _vm.focusDiv($event)
                              },
                              blur: function($event) {
                                return _vm.blurDiv($event)
                              }
                            },
                            model: {
                              value: _vm.departmentStatus,
                              callback: function($$v) {
                                _vm.departmentStatus = $$v
                              },
                              expression: "departmentStatus"
                            }
                          },
                          [
                            _c(
                              "el-option",
                              {
                                attrs: { label: _vm.$t("NORMAL"), value: "0" }
                              },
                              [_vm._v(_vm._s(_vm.$t("NORMAL")))]
                            ),
                            _c(
                              "el-option",
                              {
                                attrs: { label: _vm.$t("DISABLE"), value: "1" }
                              },
                              [_vm._v(_vm._s(_vm.$t("DISABLE")))]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticClass: "formDiv",
                        attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("NAME")) + ":")]),
                        _c("el-input", {
                          attrs: { size: "small" },
                          on: {
                            focus: function($event) {
                              return _vm.focusDiv($event)
                            },
                            blur: function($event) {
                              return _vm.blurDiv($event)
                            }
                          },
                          model: {
                            value: _vm.departmentName,
                            callback: function($$v) {
                              _vm.departmentName = $$v
                            },
                            expression: "departmentName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      "padding-top": "15px",
                      "text-align": "right",
                      "align-self": "flex-end"
                    },
                    attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          title: _vm.$t("Search"),
                          icon: "iconfont sousuo",
                          size: "mini",
                          type: "primary"
                        },
                        on: { click: _vm.refreshChange }
                      },
                      [_vm._v("  " + _vm._s(_vm.$t("Search")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "iconfont tianjia",
                          size: "mini",
                          title: _vm.$t("New"),
                          plain: ""
                        },
                        on: { click: _vm.addShow }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("New")))]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-table",
              {
                staticStyle: {
                  width: "100%",
                  "margin-bottom": "20px",
                  "margin-top": "10px"
                },
                attrs: {
                  data: _vm.tableData,
                  "row-key": "id",
                  indent: 20,
                  size: "medium",
                  "default-expand-all": "",
                  "tree-props": {
                    children: "children",
                    hasChildren: "hasChildren"
                  }
                }
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "name", label: this.$t("DEPARTMENT NAME") }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "leaderUserName",
                    label: this.$t("PERSON IN CHARGE")
                  }
                }),
                _c("el-table-column", {
                  attrs: { prop: "sort", label: this.$t("SORT"), sortable: "" }
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "number",
                    label: this.$t("NUMBER OF DEPARTMENTS")
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-link",
                            {
                              staticStyle: {
                                color: "#409EFF",
                                "text-style": "none"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.clickNumber(scope.row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(scope.row.number) +
                                  "\n                        "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: this.$t("STATE") },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("el-switch", {
                            attrs: { "active-value": 0, "inactive-value": 1 },
                            on: {
                              change: function($event) {
                                return _vm.statusChange(scope.row)
                              }
                            },
                            model: {
                              value: scope.row.status,
                              callback: function($$v) {
                                _vm.$set(scope.row, "status", $$v)
                              },
                              expression: "scope.row.status"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: this.$t("OPERATION") },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.updShow(scope.row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Edit")))]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.addChildrenShow(scope.row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("ADD SUB DEPARTMENT")))]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "block",
                staticStyle: { float: "right", margin: "30px" }
              },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.page.currentPage,
                    "page-sizes": [10, 20, 50, 40, 50, 100],
                    "page-size": _vm.page.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.page.total
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange
                  }
                })
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("Edit"),
                  visible: _vm.editFormVisible,
                  width: "50%",
                  fullscreen: "",
                  "append-to-body": true
                },
                on: {
                  "update:visible": function($event) {
                    _vm.editFormVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.updForm,
                      rules: _vm.rules,
                      "label-width": "100px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("DEPARTMENT NAME"),
                          prop: "name"
                        }
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.updForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.updForm, "name", $$v)
                            },
                            expression: "updForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("HEAD USER") } },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.updForm.leaderUserName,
                              callback: function($$v) {
                                _vm.$set(_vm.updForm, "leaderUserName", $$v)
                              },
                              expression: "updForm.leaderUserName"
                            }
                          },
                          _vm._l(_vm.leaderList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("SORT"), prop: "region" } },
                      [
                        _c("el-input-number", {
                          attrs: { "controls-position": "right" },
                          model: {
                            value: _vm.updForm.sort,
                            callback: function($$v) {
                              _vm.$set(_vm.updForm, "sort", $$v)
                            },
                            expression: "updForm.sort"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("STATUS"), prop: "region" } },
                      [
                        _c("el-switch", {
                          attrs: { "active-value": 0, "inactive-value": 1 },
                          model: {
                            value: _vm.updForm.status,
                            callback: function($$v) {
                              _vm.$set(_vm.updForm, "status", $$v)
                            },
                            expression: "updForm.status"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.editFormVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.handleUpdate }
                      },
                      [_vm._v(_vm._s(_vm.$t("Hold")))]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("ADD"),
                  visible: _vm.addChildrenVisible,
                  width: "50%",
                  fullscreen: "",
                  "append-to-body": true
                },
                on: {
                  "update:visible": function($event) {
                    _vm.addChildrenVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.addChildrenForm,
                      rules: _vm.rules,
                      "label-width": "100px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("DEPARTMENT NAME"),
                          prop: "name"
                        }
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.addChildrenForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.addChildrenForm, "name", $$v)
                            },
                            expression: "addChildrenForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("HEAD USER") } },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.addChildrenForm.leaderUserName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.addChildrenForm,
                                  "leaderUserName",
                                  $$v
                                )
                              },
                              expression: "addChildrenForm.leaderUserName"
                            }
                          },
                          _vm._l(_vm.leaderList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("SORT"), prop: "region" } },
                      [
                        _c("el-input-number", {
                          attrs: { "controls-position": "right" },
                          model: {
                            value: _vm.addChildrenForm.sort,
                            callback: function($$v) {
                              _vm.$set(_vm.addChildrenForm, "sort", $$v)
                            },
                            expression: "addChildrenForm.sort"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.addChildrenVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.addChildrenSub }
                      },
                      [_vm._v(_vm._s(_vm.$t("Hold")))]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-dialog",
              {
                attrs: {
                  title: _vm.$t("ADD"),
                  visible: _vm.addFormVisible,
                  width: "50%",
                  fullscreen: "",
                  "append-to-body": true
                },
                on: {
                  "update:visible": function($event) {
                    _vm.addFormVisible = $event
                  }
                }
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-ruleForm",
                    attrs: {
                      model: _vm.addForm,
                      rules: _vm.rules,
                      "label-width": "100px"
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: _vm.$t("DEPARTMENT NAME"),
                          prop: "name"
                        }
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: _vm.addForm.name,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "name", $$v)
                            },
                            expression: "addForm.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("HEAD USER") } },
                      [
                        _c(
                          "el-select",
                          {
                            model: {
                              value: _vm.addForm.leaderUserName,
                              callback: function($$v) {
                                _vm.$set(_vm.addForm, "leaderUserName", $$v)
                              },
                              expression: "addForm.leaderUserName"
                            }
                          },
                          _vm._l(_vm.leaderList, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.name }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: _vm.$t("SORT"), prop: "region" } },
                      [
                        _c("el-input-number", {
                          attrs: { "controls-position": "right" },
                          model: {
                            value: _vm.addForm.sort,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "sort", $$v)
                            },
                            expression: "addForm.sort"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.addFormVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Cancel")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.addFormSub }
                      },
                      [_vm._v(_vm._s(_vm.$t("Hold")))]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }